

<template>

<div 
 class="mt-5" 
 >
<!-- <v-app id="inspire"> -->
<!-- <div><blog-post title="케어사례"></blog-post></div> -->
       <!-- :height="height" -->
  <v-app
   id="inspire"
   >
 
      <!-- class="mx-auto" -->
  <v-card
      max-width="700"
      class="mx-auto"
      
    >

  <v-dialog :fullscreen="$vuetify.breakpoint.mobile">
    ...
  </v-dialog>
            <!-- <blog-post
              v-for="post in posts"
              v-bind:key="post.id"
              v-bind:title="post.title"
            ></blog-post> -->

  <!-- <v-form v-model="valid" @submit.prevent="page = 1;retrieveBbsList">             -->
  <v-container>
                
      <v-row
         align="center"
         class="mx-auto"
         
         tile>

          <!-- __________________________ADD__________________________________ -->
            <v-col
              cols="10"
            > 
            </v-col>
            <v-col
              cols="0"
              sm="11"
              align="right"
            >
               <button  v-if="currentUser"  @click="goAdd" class="btn btn-success">등록</button>
            </v-col>


         <!-- __________________________SEARCH__________________________________ -->
           <v-col
            cols="10"
            >
                  <!-- required -->
                  <!-- :rules="nameRules" -->
                  <!-- :counter="10" -->
                   <!-- ref="searchTitle" -->
                <v-text-field
                   v-model="searchTitle"
                   label="제목"
                   name="searchTitle"
                   @keyup.enter.native="page = 1; retrieveBbsList();"
                ></v-text-field>
            </v-col>
             <v-col
              cols="2"
              >
                <v-icon @click="page = 1; retrieveBbsList();">fa4 fa-search</v-icon>
            </v-col>
            
            
                



      <!-- __________________________lIST__________________________________ -->
              <v-container>
                  <v-row>
                      <v-col cols="12">
                          <slot />
                      </v-col>
                          <Bbs-Card
                              v-for="(item, i) in bbsList"
                              :key="item.id"
                              :size="layout[i]"
                              :value="item"
                              :propcurrPage="currPage"
                          />
                  </v-row>
              </v-container>
        <!-- __________________________lIST__________________________________ -->
            <!-- <v-col 
              v-for="(item,i) in bbsList"
              v-bind:key="item.id"
              cols="12"
              sm="12"
              class="mt-5"
              :size="layout[i]"
              :value="item.id"
            >
                    <v-card
                        align="center"
                        tile
                        v-bind="$attrs"
                        v-on="$listeners"
                    >
                        <v-row
                          align="center"
                          class="mx-auto"
                          tile>

                            <v-col
                              cols="12"
                            >
                                <img
                                   :src="item.fileUrl" 
                                   :alt="item.name" 
                                   height="80%" 
                                   width="80%"  
                                   class="white--text align-end"
                                   style="cursor: pointer;"
                                  >
                                        </p>
                                          <div  
                                            class="text-h7 px-4 pt-4 "
                                          >
                                              {{item.title}}
                                          </div>
                                        </p>
                            </v-col>
                      </v-row>
                      <slot />
                  </v-card>
            </v-col> -->

         <!-- ____________________paging____________________ -->
              <v-col cols="4" sm="3">
                  <!-- <v-select
                    v-model="pageSize"
                    :items="pageSizes"
                    label="Items per Page"
                    @change="handlePageSizeChange"
                  ></v-select> -->
              </v-col>
              <v-col cols="12" sm="12">
                  <v-pagination
                    v-model="page"
                    :length="totalPages"
                    total-visible="7"
                    next-icon="mdi-menu-right"
                    prev-icon="mdi-menu-left"
                    @input="handlePageChange"
                  ></v-pagination>
            </v-col>
          <!-- ____________________paging____________________ -->

      </v-row>
        </v-container>
          <!-- </v-form> -->
  </v-card>
  </v-app>


  <!-- <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Click Me
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2">
          Privacy Policy
        </v-card-title>

        <v-card-text>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </v-card-text>

        <v-divider></v-divider> -->

        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            I accept
          </v-btn>
        </v-card-actions> -->
      <!-- </v-card>
    </v-dialog> -->

    
</div>

</template>
<script>
import bbsDataService from "../services/BbsDataService";
  // Utilities
  import { mapState, } from 'vuex'
export default {
  inject: ['$validator'],
  name: "bbsList",
  components: {
      BbsCard: () => import('@/views/BbsListCard'),
    },
  
  data() {
    return {
      //  layout: [2, 2, 1, 2, 2, 3, 3, 3, 3, 3, 3],
       layout: [2,2,2,2,2,2,2,2,2,2], //10
      //  layout: [3,3,3,3,3,3,3,3,3,3,3,3], //12
       valid: false,
       nameRules: [
              v => !!v || '제목을 입력하세요.',
              v => v.length <= 10 || '10자 까지 가능합니다.',
        ],
       message: '',
      selectedFiles: undefined,
      progressInfos: [],
      message: "",
      bbsList: [],
      fileDBList:[],
      searchTitle: "",
      headers: [
        { text: "", align: "start", sortable: false, value: "fileUrl" },
        { text: "", align: "start", sortable: false, value: "title" },
    
      ],
      page: 1,
      totalPages: 0,
      pageSize: 10,
      pageSizes: [3, 6, 9],
      currentPage:0,
      posts: [
      { id: 1, title: 'My journey with Vue' },
      { id: 2, title: 'Blogging with Vue' },
      { id: 3, title: 'Why Vue is so fun' }
     ],
     dialog: false,
     currPage:Number(this.$route.params.propcurrPage),
     props: {
        propcurrPage: {
          type: Number,
          required: true,
          default: ''
        },
      },


    };
  },
  methods: {
   getRequestParams(searchTitle, page, pageSize) {
    //  console.log('###searchTitle>',searchTitle);
    //  console.log('###page>',page);
    //  console.log('###searchTitlepageSize>',pageSize);
      let params = {};
      if (searchTitle) {
        params["title"] = searchTitle;
      }

      if (page) {

        params["page"] = page - 1;

        // if(this.currPage !== page){
        //   console.log('this.currPage !== page>'+this.currPage)
        //   params["page"] = page;
        // }else if(this.currPage === page){
        //   params["page"] = page - 1;
        //   console.log('this.currPage === page>'+this.currPage)
        // }
      }
      if (pageSize) {
        params["size"] = pageSize;
      }
      return params;
    },
   retrieveBbsList() {
    //  if(!this.valid ){
    //    this.$refs.searchTitle.focus();
    //    return;
    //   }
        // <v-alert
        //   dense
        //   dismissible
        //   outlined
        //   type="info"
        // >제목을 입력하세요.</v-alert>
    
    //  console.log('this.searchTitle>' + this.searchTitle);
      // if(this.searchTitle === ''){
      //   this.$dialog.alert('Request completed!').then(function(dialog) {
      //     console.log('Closed');
      //   });
          // this.$dialog
          //   .confirm('Please confirm to continue')
          //   .then(function(dialog) {
          //     console.log('Clicked on proceed');
          //   })
          //   .catch(function() {
          //     console.log('Clicked on cancel');
          //   });
      // }

      const params = this.getRequestParams(
        this.searchTitle,
        this.page,
        this.pageSize
      );

      // console.log('bbslist search param >', params);
      bbsDataService.getAll(params)
        .then((response) => {

          // console.log('bbslist response.data>\n',response.data);
          const { bbsList, totalPages , message,currentPage } = response.data;
          
          this.bbsList = bbsList.map(this.getDisplayBbsList);
          this.totalPages = totalPages;
          this.message = message;

          this.currPage = currentPage+1;

          
          // const result = response.data.bbsList.map((item) => {
          // return {
          //     id: item.id,
          //     fileUrl: item.fileDB ? item.fileDB[0].fileUrl : null,
          //     title: item.title,
          //     // count: item.interactions_count ? item.interactions_count : item.client_interactions_count
          //   };
          // }).sort((a, b) => b.count - a.count); 

          // this.bbsList  = result;
          // console.log('result>' + JSON.stringify(result));
          
          // const { bbsList, totalPages , message} = response.data;
          // console.log('totalpages>'+totalPages);
          // console.log('message>'+message);
          
          // this.bbsList = bbsList.map(this.getDisplayBbsList);
          // // this.bbsList = bbsList.map(this.getDisplayBbsList);
          // // this.fileDBList = fileDBList.map(this.getDisplayfileDBList);
          // this.totalPages = totalPages;
          // this.message = message;
          // console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    getDisplayBbsList(item) {
        //  console.log('getDisplayBbsList>'+ item.fileDB[0] === null  ? 'aaa' : item.fileDB[0].fileUrl);
      
      let fileurl = item.fileDB.map(function (fileurl) {
            return fileurl.fileUrl
      });

      // console.log('fileurl>' + fileurl);

      return {
        id: item.id,
        fileUrl: fileurl,
        title: item.title.length >= 10 ? item.title.substr(0, 20) + "..." : item.title ,

      };
    },
    // getDisplayfileDBList(fileDBList) {
    //   return {
    //     fileId: fileDBList.id,
    //     fileUrl: fileDBList.fileUrl,
    //     // name: fileDBList.name.substr(0, 20) + "...",
    //     // description: tutorial.description.substr(0, 20) + "...",
    //     // status: tutorial.published ? "Published" : "Pending",
    //   };
    // },
     handlePageChange(value) {
        this.page = value;
        this.retrieveBbsList();
    },
    //  handlePageSizeChange(size) {
  //     this.pageSize = size;
  //     this.page = 1;
  //     this.retrieveBbsList();
  //   },
    goAdd(){
      this.$router.push({
                                name:'bbsadd/new',
                                params:{
                                  new : 'new'
                                }
                        });
     }
  }, //end method

  

  mounted() {
    if(this.currPage){
      this.page =this.currPage;
    }
    this.retrieveBbsList();
    
  // console.log(this.$vuetify.breakpoint.width)
  // console.log(this.$vuetify.breakpoint.mobile)

    // bbsDataService.getAll().then((response) => {
    //   console.log("mounted-getFiles>" + JSON.stringify(response.data));
    //   this.fileInfos = response.data;
    // });
  },
  computed: {
      // ...mapState(['bbsList']),
      currentUser() {
      // console.log("computed-currentUser");
        return this.$store.state.auth.user;
      },
      height () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 220
          case 'sm': return 400
          case 'md': return 500
          case 'lg': return 600
          case 'xl': return 800
        }
      },
    },
   watch: {
      page () {
        window.scrollTo(0, 0)
      },
   },
};
</script>
<style scoped>
.v-image__image {
  transition: .3s linear;
}
</style>